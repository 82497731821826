.supplierList {
  background: white;
  padding: 30px 25px;
  border-radius: 10px;
  border: solid $grey-border 1px;
  // width: 820px;

  table {
    tbody {
      border-top: 1px solid #f6f6f6 !important;
      tr,
      td {
        border: none;
        display: flex;
        align-items: center;
      }
      tr:nth-child(even) {
        background: #fcfcfc;
      }
    }
    thead {
      td {
        font-family: "Nunito-Bold";
        font-weight: 700;
        text-align: center;
        font-size: 12px;
        text-align: left;
      }
    }
    td {
      vertical-align: middle;
      font-size: 1em;
      text-align: center;
    }
    .supplier {
      padding: 0px;
      &-delete {
        div {
          background: #fdecec;
          padding: 0px;
          @include flex-cAlign-cJustify;
          height: 45px;
          width: 45px;
          border-radius: 8px;
          margin: 0px auto;
          svg {
            padding: 0px;
            width: 20px;
            height: 20px;
          }
        }
      }
      &-name {
        @include flex-cAlign;
        font-family: "Nunito-Bold";
        font-size: 12px;
        div {
          @include flex-cAlign-cJustify;
          width: 35px;
          height: 30px;
          padding: 0px;
          background: $grey-input;
          border-radius: 8px;
          margin-right: 10px;
          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
      &-count {
        span {
          background: #e8f2fc;
          padding: 7px 25px;
          color: #1479e0;
          border-radius: 8px;
          font-size: 12px;
        }
      }
    }
  }

  &__modal {
    padding: $space-s;
    border-radius: 10px;
    border: solid $grey-border 1px;
  }
}
