.dateFilter {
  @include flex-cAlign;
  margin-right: $space-med;
  input {
    border-radius: 10px;
    border: none;
    max-width: 190px;
    padding: 9px 20px;
    background-color: white;
    &:first-of-type {
      margin-right: 24px;
    }
    &:active,
    &:focus {
      box-shadow: none;
    }
    &[type="date"]::-webkit-calendar-picker-indicator {
      background: url("../assets/images/general/Calendar.svg") no-repeat;
      border-width: thin;
      color: rgba(0, 0, 0, 0);
      cursor: pointer;
      display: block;
      height: 20px;
      opacity: 1;
      width: 20px;
    }
  }
  label {
    margin-right: 15px;
  }
  span {
    color: red;
    margin-left: 12px;
  }
}
