.multiSelectContainer{
    & li:hover{
        background: $shopeeOrange;
    }
    .search-wrapper{
        background-color: $grey-input;
        border: none;
        border-radius: 10px;
        color: $grey-border;
        font-size: 14px;
        padding: 10px;
        .chip{
            background: $shopeeOrange;
            border-radius: 25px;
        }
    }
    .highlightOption{
        background: $shopeeOrange !important;
    }
}