.inventoryCard {
  background: white;
  padding: 30px 25px;
  border-radius: 10px;
  margin-bottom: $space-s;
  // width: 820px;

  &.hide {
    .inventoryCard__content {
      max-height: 0px;
      overflow: hidden;
      margin-top: 0px;
    }
  }

  &__content {
    max-height: 1000px;
    transition: all 0.2s ease;
    margin-top: 35px;
  }

  &__header {
    width: 100%;
    background: none;
    border: none;
    @include flex-cAlign;
    justify-content: space-between;
    h2 {
      font-size: 1.2em;
      margin: 0px;
      font-family: "Nunito-Bold";
      color: $black;
    }
  }

  table {
    tbody {
      border-top: 1px solid #f6f6f6 !important;
      tr,
      td {
        border: none;
        .loading { padding: 25px; }
      }
      tr:nth-child(even) {
        background: #fcfcfc;
      }
    }
    thead {
      td {
        font-family: "Nunito-Bold";
        font-weight: 700;
        text-align: center;
      }
    }
    td {
      vertical-align: middle;
      font-size: 1em;
      text-align: center;
    }
    .inv {
      padding: 0px;
      &-delete {
        div {
          padding: 0px;
          @include flex-cAlign-cJustify;
          height: 45px;
          width: 45px;
          border-radius: 10px;
          margin: 0px auto;
          cursor: pointer;
          svg {
            padding: 0px;
            width: 20px;
            height: 20px;
          }
        }
      }
      &-name {
        @include flex-cAlign;
        font-family: "Nunito-Bold";
        font-size: 14px;
        padding: $space-s $space-xs;
        cursor: pointer;
        div {
          @include flex-cAlign-cJustify;
          width: 45px;
          height: 45px;
          padding: 0px;
          background: $grey-input;
          border-radius: 8px;
          margin-right: 10px;
          img {
            max-width: 35px;
            height: auto;
          }
        }
      }
      &-count {
        span {
          background: #e8f2fc;
          padding: 7px 25px;
          color: #1479e0;
          border-radius: 8px;
          font-size: 12px;
        }
      }
    }
  }

  &__modal {
    background: white;
    border-radius: 10px;
    border: solid $grey-border 1px;
    font-size: 12px;
    margin-bottom: $space-s;
    padding: 30px 25px;
    &--title {
      margin-bottom: $space-s;
      font-size: 16px;
      font-family: "Nunito-Bold";
    }
    &--content {
      td {
        text-align: center;
      }

      .inv {
        &-name {
          span {
            font-size: 12px;
          }
          div {
            @include flex-cAlign-cJustify;
            width: 45px;
            height: 45px;
            padding: 0px;
            background: $grey-input;
            border-radius: 8px;
            margin-right: 10px;
            img {
              max-width: 35px;
              height: auto;
            }
          }
        }
        &-count {
          text-align: center;
          width: 100px;
        }
        &-remarks {
          width: 145px;
        }
      }
    }
  }
}
