// Forms
.form {
  &-control,
  &-select {
    background-color: $grey-input;
    border: none;
    border-radius: 10px;
    color: $black;
    font-size: 14px;
    padding: 10px;
    &:focus {
      box-shadow: 0 0 0 2.5px $orange-light;
    }
  }
  &-label {
    margin-bottom: 0px;
  }
  &-control {
    &[type="date"]::-webkit-calendar-picker-indicator {
      background: url("../assets/images/general/Calendar.svg") no-repeat;
      border-width: thin;
      color: rgba(0, 0, 0, 0);
      cursor: pointer;
      display: block;
      height: 20px;
      opacity: 1;
      width: 20px;
    }
    &[type="search"] {
      position: relative;
    }
  }
  &-imagecontainer {
    background-color: $grey-input;
    height: 253px;
    border-radius: 10px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  &__input-container {
    width: 100%;
    margin-right: $space-s;
  }
  &-upload{
    background: $orange-light;
    border-radius: 10px;
    border: solid 1px;
    color: $shopeeOrange;
    padding: 20px;
    width: 100%; 
  }
}
textarea {
  height: 108px;
}
.css-1s2u09g-control{
  background-color: $grey-input !important;
  border: none;
  border-style: none !important;
  border-radius: 10px !important;
  color: $black;
  font-size: 14px;
}
