.sppInventory {
  padding: 55px 70px;
  @include wrap;
  margin: 0 auto;
  &__content {
    padding-top: 65px;
  }
  // overwrite bootstrap styles here
  .btn:not(.dropdown-toggle) {
    border-radius: 6px;
    font-size: 14px;
    border-color: $shopeeOrange;
    padding: $space-xs $space-s;
    height: 42px;
    min-width: $button-min-width;
    img,
    svg {
      margin-right: 10px;
    }
    &:focus,
    &:active {
      box-shadow: 0 0 0 0.25rem #ed4d2d57;
    }
    &.export {
      padding: 0px;
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 10px;
        text-decoration: none;
        color: #4d4d4d;
      }
      &:hover a {
        color: white;
      }
    }
    &.white {
      min-width: 100px;
      background: white;
      border: none;
      box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
      color: $black;
      filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
    @extend .mr-s;
      &:hover {
        background-color: $shopeeOrange;
        border-color: $shopeeOrange;
        color: white;
      }
    }
    &.blue {
      border: none;
      background: $blue;
      color: white;
    }
  }
}
.btn {
  border-radius: 6px;
  font-family: "Nunito-Regular";
  font-size: 14px;
  min-width: $button-min-width;
  padding: $space-xs $space-s;
@include flex-cJustify-cAlign;
  &-primary {
    background-color: $shopeeOrange;
    border-color: $shopeeOrange;
    color: white;
    svg{
      fill: white;
    }
    &:active,
    &:focus,
    &:hover {
      background-color: $shopeeOrange;
      border-color: $shopeeOrange;
      color: white;
    }
    &:disabled {
      border-color: $shopeeOrange;
      background-color: $shopeeOrange;
    }
  }
  &-secondary {
    box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
    border: none;
    background: white;
    color: $black;
    @extend .mr-s;
    &:active,
    &:focus,
    &:hover {
      background-color: $shopeeOrange;
      border-color: $shopeeOrange;
      color: white;
    }
  }
  &-outline {
    background-color: $orange-light;
    color: $shopeeOrange;
    @extend .ml-s;
    &:hover {
      background-color: $shopeeOrange;
      color: white;
    }
  }
}

.actionButton{
  cursor: pointer;
  color: $shopeeOrange;
  font-family: "Nunito-Bold";
  @include flex-cAlign;
  &--edit,
  &--delete,
  &--add{
    width:36px;
    height: 37px;
    border-radius: $border-radius-s;
    cursor: pointer;
    padding: 8px;
    svg{
      width: $icon-size-m;
      height: $icon-size-m;
    }
  }
  &--edit{
    background-color: $blue-bg;
    margin-right: $space-xs;
  }
  &--delete{
    background-color: $red-bg;
    svg{
      fill: $red;
    }
  }
  &--add{
    background-color: $shopeeOrange-bg;
    margin-right: $space-xs;
    svg{
      fill: $shopeeOrange;
    }
  }
}

.modal-title {
  .success {
    color: green;
  }
}