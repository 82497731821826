.modal {
  font-family: "Nunito-Regular";
  color: $black;
  &-header {
    border-bottom: none;
    padding: $space-s $space-s 0px $space-s;
  }
  &-body{
    max-height: 350px;
    overflow-y: auto;
  }
  &-footer {
    border-top: none;
    padding: $space-s;
  }
  &-title {
    font-family: "Nunito-Bold";
    font-size: 20px;
  }
  &-dialog {
    max-width: $modal-width;
  }
  &-body {
    margin-top: 60px;
    padding-top: 2px;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      border-left: 0;
      border-right: 0;
      background-color: $grey-dark;
    }
  }
  .svg-search{
    position: absolute;
    width: auto;
    right: 35px;
    top: 12px;
    fill: $grey-dark;
  }
}
