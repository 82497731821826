$space-xs: 10px;
$space-s: 20px;
$space-med: 45px;

// Spacing
.mt-m {
  margin-top: $space-med;
}
.mb-m {
  margin-bottom: $space-med;
}
.mr-s {
  margin-right: $space-s;
}
.ml-s{
  margin-left: $space-s;
}
.mr-xs{
  margin-right: $space-xs;
}

.line-break{
  white-space: pre-line;
}
