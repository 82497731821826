.navBar {
  flex-direction: column;
  align-items: flex-start;
  h1 {
    font-size: 28px;
    font-weight: 700;
    color: $shopeeOrange;
    margin-bottom: 0px;
  }
  nav {
    margin-top: 17px;
    a {
      font-size: 16px;
      margin-right: 20px;
      text-decoration: none;
      position: relative;
      color: $grey;
      font-family: "Nunito-Bold";
      &::after {
        content: "";
        transition: all 0.2s ease;
        position: absolute;
        height: 3px;
        width: 0%;
        bottom: -20px;
        left: 0;
      }
      &:hover {
        color: $shopeeOrange;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          background: #ed4d2d;
          border-radius: 10px;
        }
      }
    }
  }
  .active {
    color: $shopeeOrange;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      background: #ed4d2d;
      border-radius: 10px;
    }
  }
}
.customButton{
	align-items: center;
	display: flex;
	justify-content: flex-end;
	margin-bottom: $space-med;
}