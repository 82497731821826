.issuance {
  &__search {
    @include flex-cAlign;
    padding-right: 10px;
    border-radius: 10px;
    &, input {
      background: white;
      box-shadow: none !important;
    }
  }
  &__merchant-filter {
    background: white;
    margin-right: 22px;
  }
  &__merchantLabel {
    margin-bottom: 0px;
    span {
      margin-left: 12px;
      background: #ed4d2d;
      color: white;
      border-radius: 50%;
      padding: 0px 5px;
      font-size: 14px;
      cursor: pointer;
    }
    p {
      margin-top: 40px;
      text-align: center;
    }
  }
  .customButton {
    & > div {
      display: flex;
    }
    &:nth-of-type(2) {
      justify-content: space-between;
    }
  }
}