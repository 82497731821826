.loading {
  text-align: center;
  padding: 50px;
}

.modal-content {
  .loading {
    @extend .loading;
    padding: 0px;
    .spinner-border {
      height: 1rem;
      width: 1rem;
    }
  }
}

.loading-page {
  height: calc(100vh - 385px);
  @include flex-cAlign-cJustify;
}