$black: #4d4d4d;
$blue: #1479e0;
$blue-bg: rgba(91, 147, 255, 0.2);
$grey-border: rgba(148, 148, 148, 0.2);
$grey-dark: #949494;
$grey-input: #f7f7f8;
$grey: #9c9cb6;
$orange-light: rgba(238, 77, 45, 0.2);
$red: #C53B3B;
$red-bg: rgba(197, 59, 59, 0.1);
$shopeeBlue: #113366;
$shopeeOrange: #ed4d2d;
$shopeeOrange-bg:rgba(238, 77, 45, 0.2);

$logo-size-s: 36px;
$logo-size-m: 50px;

$icon-size-m: 20px;

$modal-width: 615px;
$button-min-width: 140px;

$border-radius-s: 10px;
@mixin wrap {
  max-width: 1386px;
  margin-left: auto;
  margin-right: auto;
}

@mixin flex-cAlign-cJustify {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-cJustify-cAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-cAlign {
  display: flex;
  align-items: center;
}

@mixin flex-cJustify {
  display: flex;
  justify-content: center;
}
