.issuanceCard {
  background: white;
  padding: 30px 25px;
  border-radius: 10px;
  margin-bottom: $space-s;
  // width: 820px;

  &__status {
    display: flex;
    justify-content: flex-end;
    button {
      text-transform: capitalize;
    }
    a:active {
      background-color: $shopeeOrange !important;
      box-shadow: 0 0 0 0.25rem #ed4d2d1c  !important;
    }
    .dropdown-toggle {
      background-color: $shopeeOrange !important;
      box-shadow: 0 0 0 0.25rem #ed4d2d1c  !important;
    }
  }

  & > div {
    & > a {
      font-size: 16px;
      font-weight: bold;
      color: #4d4d4d;
    }
  }

  &.hide {
    .issuanceCard__content {
      max-height: 0px;
      overflow: hidden;
      margin-top: 0px;
    }
  }

  &__header {
    width: 100%;
    background: none;
    border: none;
    @include flex-cAlign;
    justify-content: space-between;
    h2 {
      font-size: 1.2em;
      margin: 0px;
      font-family: "Nunito-Bold";
    }
  }

  &__content {
    @include flex-cAlign;
    max-height: 1000px;
    transition: all 0.2s ease;
    margin-top: 35px;
    div {
      b {
        font-family: "Nunito-Bold";
        font-weight: bold;
      }
      &:first-child {
        border-right: 2px;
        border-top: 0px;
        border-bottom: 0px;
        border-left: 0px;
        border-style: solid;
        border-color: #f6f6f6;
      }
      &:nth-child(2) {
        padding-left: 50px;
      }
      width: 50%;
      padding: 10px 30px;
    }
  }
  svg{
    fill: $shopeeOrange;
  }

  &__description {
    max-height: 800px;
    white-space: pre-line;
    overflow: auto;
  }
}
