.fileUpload {
  position: relative;
  .loading {
    display: none;   
  }
  &--loading {
    @extend .fileUpload;
    .loading {
      position: absolute;
      left: 50%;
      top: 34%;
      display: block;   
    }
  }
}