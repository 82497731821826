.customPagination {
  .pagination {
    justify-content: flex-end;
  }
  a {
    color: $shopeeOrange;
  }
  li {
    a:hover {
      background-color: #ed4d2d36;
      color: white;
    }
    a:focus {
      box-shadow: 0 0 0 0.25rem #ed4d2d36;
    }
     &,a,span {
      border-color:#DFE3E8 !important;
      border-radius: 4px;
      font-size: 14px;
      font-family: "Nunito-Bold";
    }
    margin: 0px 4px;
    &.disabled {
      background: #E2E2E2;
      opacity: 0.5;
      border-radius: 4px;
      color: white;
    }
    &.active {
      .page-link {
        background-color: $shopeeOrange;
        border-color: $shopeeOrange;
      } 
    }
  }
}