.list {
  border-radius: 10px;
  background: white;
  padding: 15px 25px;
  &__loader:not(.loading) {
    div { display: none ;}
  }
  th, td { text-align:  center; }
  tbody, thead, tr, th, td {
    border-top: none !important;
    border: none;
    color: black;
    font-size: 14px;
    vertical-align: middle;
  }
  thead {
    margin-bottom: 19px;
    font-weight: 600;
    border-bottom: solid $grey-input 1px;
    th {
      font-family: "Nunito-Bold";
    }
  }
  tbody {
    tr {
      background: white;
      &:nth-child(even) {
        background: #FAFAFB;
      }
    }
    td {
      font-weight: normal;
    }
  }
}