@font-face {
  font-family: "Nunito-Regular";
  src: url("./assets/fonts/Nunito-Regular.ttf");
}

@font-face {
  font-family: "Nunito-Bold";
  src: url("./assets/fonts/Nunito-Bold.ttf");
}

@font-face {
  font-family: "Nunito-Medium";
  src: url("./assets/fonts/Nunito-SemiBold.ttf");
}

@import "~bootstrap/scss/bootstrap";

@import "./scss/mixins";

body {
  background: #f7fafc;
  color: $black;
  font-family: "Nunito-Regular";
  font-size: 14px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border-left: 0;
    border-right: 0;
    background-color: $grey-dark;
  }
  .logo-container{
    width: $logo-size-s;
    height: $logo-size-m;
      img{
        width: 100%;
      }
  }
  h5{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0px;
  }
}

@import "./scss/utilities";
@import "./scss/form";
@import "./scss/modal";
@import "./scss/list";
@import "./scss/navBar";
@import "./scss/loading";
@import "./scss/returns";
@import "./scss/dateFilter";
@import "./scss/sppInventory";
@import "./scss/inventoryCard";
@import "./scss/addReturnsModal";
@import "./scss/fileUpload";
@import "./scss/issuanceCard";
@import "./scss/deliveryCard";
@import "./scss/supplierList";
@import "./scss/customPagination";
@import "./scss/issuance";
@import "./scss/search";

