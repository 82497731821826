.deliveryCard {
  background: white;
  padding: 30px 25px;
  border-radius: 10px;
  margin-bottom: $space-s;
  // width: 100%;

  &__content {
    @include flex-cAlign;
    div {
      b {
        font-family: "Nunito-Bold";
        font-weight: bold;
      }
      table {
        width: 100%;
      }
      &:first-child {
        border-right: 2px;
        border-top: 0px;
        border-bottom: 0px;
        border-left: 0px;
        border-style: solid;
        border-color: #f6f6f6;
      }
      &:nth-child(2) {
        padding-left: 50px;
        td {
          padding-bottom: 1rem;
          &:first-child {
            font-family: "Nunito-Bold";
          }
          &:nth-child(3) {
            text-align: right;
          }
        }
      }
      width: 50%;
      padding: 10px 30px;
    }
  }

  &__description {
    max-height: 800px;
    white-space: pre-line;
    overflow: auto;
  }
}
